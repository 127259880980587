// import firebase from 'firebase/app'
import * as firebase from "firebase/app"

import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import 'firebase/database'

export const db = firebase.database()

export const firebasedb = firebase

// export const dessRef = db.ref('desserts')

export const usersRef = db.ref('Users')